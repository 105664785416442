<template>
    <div class="policy">
        <div class="secondMenu">
            <div class="w-1400 clearfix">
                <div class="page-name fl">脱贫攻坚</div>
                <ul class="page-list fl">
                    <li class="page-item fl" :class="{act:actPage === 'zeren'}"><a @click="toPage('zeren')">责任落实</a> </li>
                    <li class="page-item fl" :class="{act:actPage === 'fangpin'}"><a @click="toPage('fangpin')">防贫监测</a> </li>
                    <li class="page-item fl" :class="{act:actPage === 'wugong'}"><a @click="toPage('wugong')">务工就业</a> </li>
                    <li class="page-item fl" :class="{act:actPage === 'zijin'}"><a @click="toPage('zijin')">项目资金</a> </li>
                </ul>
            </div>
        </div>
        <!--脱贫攻坚-->
        <div class="ox">
            <div class="sxzc w-1400">
                <div class="policy-h3 clearfix" ref="zeren">
                    <div class="fl">责任落实</div>
                    <div class="fr">
                        <router-link to="/List?infoType=589678048960581&type=责任落实"> 更多<i class="el-icon-arrow-right"></i></router-link>
                    </div>
                </div>
                <div class="sxzc-mian mt-10">
                    <el-row :gutter="30">
                        <!-- <el-col :span="5">
                            <div class="sxzc-tab">
                                <div class="sxzc-tab-item" v-for="(item) in tabList" :key="item" :class="{act:item== SXZCparams.filter.secondInfoType}" @click="change(item)">
                                    <i class="el-icon-postcard"></i> {{item}}
                                </div>
                            </div>
                        </el-col> -->
                        <el-col :span="13">
                            <div class="sxzc-cal">
                                <el-carousel trigger="click" height="510px"  arrow="never">
                                    <el-carousel-item v-for="item in SXZCdata.slice(0,4)" :key="item.id+'cal'">
                                        <router-link :to="`/detail?id=${item.id}&type=责任落实&oParams=${JSON.stringify(SXZCparams)}`">
                                            <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                        </router-link>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </el-col>
                        <el-col :span="11">
                            <div class="sxzc-list">
                                <div class="zc-item ellipsis" v-for="item in SXZCdata" :key="item.id" >
                                    <router-link :to="`/detail?id=${item.id}&type=责任落实&oParams=${JSON.stringify(SXZCparams)}`">{{item.title}}</router-link>                                
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!--防贫监测-->
            <div class="butie w-1400">
                <div class="policy-h3 clearfix" ref="fangpin">
                    <div class="fl">防贫监测</div>
                    <div class="fr">
                        <router-link to="/List?infoType=589921984745541&type=防贫监测">
                            更多<i class="el-icon-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="gg-main mt-40">
                    <el-row :gutter="30">
                        <el-col :span="10">
                            <div class="gg-list">
                                <ul>
                                    <li class="gg-item" v-for="item in BTZCdata" :key="item.id" @click="goDetail(item.id,'防贫监测',JSON.stringify(BTZCparams))" >
                                        <div class="gg-text ellipsis">{{item.title}}</div>
                                        <div class="gg-time">{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :span="14">
                            <div class="gg-banner">
                                <div class="gg-pic-big">
                                    <router-link :to="`/detail?id=${BTZCdata[(Math.abs(transNum/237))].id}&type=防贫监测&oParams=${JSON.stringify(BTZCparams)}`">
                                        <el-image :src="BTZCdata[(Math.abs(transNum/237))].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                    </router-link>
                                </div>
                                <div class="cal-small">
                                    <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${237*calList.length*2}px;transform:translateX(${transNum}px)`">
                                        <li class="cal-smal-item fl" :class="{isSlace:(Math.abs(transNum/237)+1 === index)}" v-for="(item,index) in calList" :key="index +'cal'">
                                            <div class="star-pic">
                                                <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                            </div>
                                            <div class="ceng" v-show="!(Math.abs(transNum/237)+1 === index)"></div>
                                        </li>
                                    </ul>
                                    <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                                    <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                                </div>
                            </div>
                        </el-col>
                        
                    </el-row>
                </div>
            </div>
            <!--务工就业-->
            <div class="nongcun w-1400">
                <div class="policy-h3 clearfix" ref="wugong">
                    <div class="fl">务工就业</div>
                    <div class="fr">
                        <router-link to="/List?infoType=589922335830085&type=务工就业">
                            更多<i class="el-icon-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="nc-main mt-10">
                    <div class="nc-box">
                        <router-link :to="`/detail?id=${ncCalList[(Math.abs(transNumNc/245)+2)].id}&type=务工就业&oParams=${JSON.stringify(NCXMparams)}`">
                            <el-image :src="ncCalList[(Math.abs(transNumNc/245)+2)].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                        </router-link>
                    </div>
                    <div class="nc-name">{{ncCalList[(Math.abs(transNumNc/245)+2)].title}}</div>
                    <div class="car-box">
                        <div class="nc-cal-small">
                            <ul class="cal-small-list clearfix" :class="{istransNc}" :style="`width:${245*ncCalList.length*2}px;transform:translateX(${transNumNc}px)`">
                                <li class="nc-cal-smal-item fl" :class="{isSlaceNc:(Math.abs(transNumNc/245)+2 === index)}" v-for="(item,index) in ncCalList" :key="index +'cal'">
                                    <div class="nc-star-pic">
                                        <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                    </div>
                                    <div class="ceng" v-show="!(Math.abs(transNumNc/245)+2 === index)"></div>
                                </li>
                            </ul>
                            <div class="nc-cal-btn prev" @click="ncPrev"><i class="el-icon-arrow-left"></i></div>
                            <div class="nc-cal-btn next" @click="ncNext"><i class="el-icon-arrow-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--项目资金-->
            <div class="sv-1f w-1400">
                <div class="policy-h3 clearfix" ref="zijin">
                    <div class="fl">项目资金</div>
                    <div class="fr">
                        <router-link to="/List?infoType=589935358808133&type=项目资金">
                            更多<i class="el-icon-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="mt-10">
                    <el-row :gutter="30" >
                        <el-col :span="14">
                            <div class="sv-baneer">
                                <div class="bigpic fl" >
                                    <el-carousel height="420px" indicator-position="none" arrow="never" ref="cars" @change="carsChange">
                                        <el-carousel-item v-for="item in ZHXXtopdata" :key="item.id">
                                            <el-image style="width:100%;height:100%" fit="cover" :src="item.photoUrls" @click="goDetail(item.id,'项目资金',JSON.stringify(ZHXXparams))"></el-image>
                                        </el-carousel-item>
                                    </el-carousel> 
                                </div>
                                <div class="sm-pic fr">
                                    <ul class=" ">
                                        <li class="sm-item" v-for="(item,k) in ZHXXtopdata" :key="item.id+'top'" :class="{act:k=== actK}">
                                            <el-image style="width:100%;height:100%" :src="item.photoUrls" @click="carsClick(k)"></el-image>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <div class="sc-r">
                                <ul class="sv-list">
                                    <li class="sv-item" v-for="item in ZHXXdata" :key="item.id" @click="goDetail(item.id,'项目资金',JSON.stringify(ZHXXparams))">
                                        <div class="ellipsis sv-title">{{item.title}}</div>
                                        <div>{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                            
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
export default {
    data(){
        return{
            actPage:'zeren',
            transNum:0,
            transNumNc:0,
            ncCalList:[{},{},{},{},{}],
            calList:[{},{},{},{},{}],
            istrans:true,
            istransNc:true,
            actimg:'',
            ZHXXact:'',
            tabList:['城镇职工社会保险','考试招生','住房保障与房地产','医疗保险','市场监管','身份户籍','交通出行','卫生健康'],
            SXZCparams:{//责任落实
                currentPage: 1,
                filter: {infoType:589678048960581,},
                pageSize: 12,
            },
            SXZCdata:[],
            BTZCparams:{//防贫监测
                currentPage: 1,
                filter: {infoType:589921984745541},
                pageSize: 16,
            },
            BTZCdata:[{},{}],
            NCXMparams:{//务工就业
                currentPage: 1,
                filter: {infoType:589922335830085,},
                pageSize: 8,
            },
            ZHXXdata:[],
            ZHXXtopdata:[],
            ZHXXparams:{//项目资金
                currentPage: 1,
                filter: {infoType:589935358808133},
                pageSize: 10,
            },
            actK:0,
        }
    },
    methods:{
        toPage(a){
            this.actPage = a
            this.$refs[a].scrollIntoView({ behavior: 'smooth' })
        },
        ncNext(){
            if(this.transNumNc === -this.ncCalList.length*245/2){
                this.transNumNc = 0
                this.istransNc = false;
                setTimeout(() => {
                    this.istransNc = true;
                    this.transNumNc -= 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNumNc -= 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        ncPrev(){
            if(this.transNumNc === 0){
                this.transNumNc = -this.ncCalList.length*245/2
                this.istransNc = false;
                setTimeout(() => {
                    this.istransNc = true;
                    this.transNumNc += 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNumNc += 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        getNCXMdata(){
            GetList(this.NCXMparams).then(res=>{
                this.ncCalList = res.data.data.list;
                this.ncCalList =this.ncCalList.concat(this.ncCalList)
            })
        },
        next(){
            this.transNum -= 237
            if(this.transNum === -(this.calList.length-2)*237){
                this.istrans = false;
                this.$nextTick(()=>{
                    this.istrans = true;
                    this.transNum = 0
                })
            }
        },
        prev(){
            if(this.transNum === 0){
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum = -(this.calList.length-3)*237
                }, 1);
            }else{
                this.transNum += 237
            }
        },
        change(a){
            this.SXZCparams.filter.secondInfoType = a;
            this.getSXZCdata();
        },
        getSXZCdata(){
            GetList(this.SXZCparams).then(res=>{
                this.SXZCdata = res.data.data.list;
            })
        },
        getBTZCdata(){
            GetList(this.BTZCparams).then(res=>{
                this.BTZCdata = res.data.data.list;
                let arr = res.data.data.list.slice(0,5);
                arr.push(arr[0])
                arr.unshift(arr[arr.length-2])
                this.calList = arr
            })
        },
        change1(a){
            this.BTZCparams.filter.secondInfoType = a;
            this.getBTZCdata();
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
        carsChange(k){
            this.actK = k;
        },
        getZHXXdata(){
            GetList(this.ZHXXparams).then(res=>{
                this.ZHXXdata = res.data.data.list;
                this.ZHXXtopdata = res.data.data.list.filter((item,index)=>index<4);
                this.ZHXXact = this.ZHXXtopdata[0].photoUrls;
            })
        },
        carsClick(k){
            this.$refs['cars'].setActiveItem(k);
        }
    },
    mounted(){
        this.getSXZCdata();
        this.getBTZCdata();
        this.getNCXMdata();
        this.getZHXXdata();
        if(this.$route.query.page){
            this.$nextTick(()=>{
                this.toPage(this.$route.query.page)
            })
        }
    }
}
</script>
<style scoped>
.scroll-box{
    height: 800px;
    overflow-y: auto;
}
.scroll-box::-webkit-scrollbar {
  width: 0px; /* 设置滚动条的宽度 */
}
 
.scroll-box::-webkit-scrollbar-thumb {
  background-color: red; /* 设置滚动条滑块的颜色 */
  border-radius: 0px; /* 设置滚动条滑块的圆角 */
}
 
.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在悬停时的颜色 */
}
 
.scroll-box::-webkit-scrollbar-track {
  background-color: #eee; /* 设置滚动条背景的颜色 */
}
.isSlaceNc{
    transform: scale(1.2);
    border: 2px solid #fff;
}
.isSlaceNc .star-info{
    background: #f37b1c;
    color: #fff;
}
.nc-cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.nc-cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.nc-star-pic{
    height: 142.5px;
}
.nc-cal-smal-item{
    width: 220px;
    height: 142.5px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.nc-cal-small{
    height: 160px;
    padding-top:8px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.car-box{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 175px;
    padding-top: 8px;
    width: 100%;
}
.nc-name{
    padding: 10px 20px;
    font-size: 20px;
    background: #3aceda;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
}
.nc-box{
    height: 100%;
}
.nc-main{
    height: 700px;
    overflow: hidden;
    position: relative;
}
.nongcun .t2{
    display: flex;
    justify-content: space-between;
}
.nongcun .t2>span:last-child{
    font-size: 16px;
    font-weight: 400;
}
.nongcun .t2>span:last-child:hover{
    color: #666;
    cursor: pointer;
}
.gg-text.ellipsis{
    width: 75%;
}
.mt-40{
    margin-top: 40px;
}
.gg-item:hover{
    color: #0e6ac1;
    cursor: pointer;
}
.gg-item{
    line-height: 36.25px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
}
.gg-list{
    padding: 20px;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
    height: 620px;
    overflow: hidden;
}
.gg-banner{
    position: relative;
    height: 620px;
    overflow: hidden;
}
.gg-pic-big{
    height: 500px;
}
.istrans{
    transition: transform 1.2s;
}
.istransNc{
    transition: transform 1.2s;
}
.prev{
    left: 0px;
}
.next{
    right: 0;
}
.star-pic{
    height: 120px;
}
.cal-btn{
    width: 30px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 212px;
    height: 120px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 140px;
    /* margin-top: 20px; */
    padding-top: 15px;
    width: 686px;
    margin: 20px auto;
    overflow: hidden;
    position: relative;
    bottom: 20px;
}
.menu-item{
    margin-top: 30px;
}
.butie-submenu{
    padding: 0px 40px;
}
.zc-item:hover{
    color:#0e6ac1;
    cursor: pointer;
}
.zc-item{
    line-height: 40px;
    font-size: 16px;
    border-bottom:1px dashed #eee;
}
.sxzc-list{
    padding: 15px 20px;
    height: 510px;
    overflow: hidden;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
}
.sxzc-cal >>> .el-carousel__button{
    height: 6px;
    width: 50px;
}
.sxzc-cal{
    height: 510px;
}
.sxzc-tab-item~.sxzc-tab-item{
    margin-top: 10px;
}
.sxzc-tab-item:hover,.sxzc-tab-item.act,.menu-item:hover,.menu-item.act{
    color:#fff;
    background: #0e6ac1;
    cursor: pointer;
}
.sxzc-tab-item,.menu-item{
    height: 55px;
    line-height: 55px;
    background: #ddd;
    border-radius: 6px;
    font-size: 20px;
    padding-left: 8px;
    font-weight: 800;
    font-family: '宋体';
}
.sxzc-tab{
    height: 510px;
}
.policy-h3{
    line-height: 42px;
    font-size: 20px;
    border-bottom: 1px solid #eee;
}
.policy a{
    color: #666;
}
.policy{
    font-family: '微软雅黑';
    color: #666;
}
.sv-item:hover{
    color:#0e69c2;
    cursor: pointer;
}
.sv-item{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #555;
    line-height: 35px;
    border-bottom: 1px solid #eee;
}
.sv-item .sv-title {
    width: calc(100% - 100px);
    /* font-weight: 600 */
}
.sv-list{
    margin-top: 10px;
}
.sv-h4 .arrow-r{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right2.png) no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
}
.sv-h4{
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    border-bottom: 1px solid #E4E7ED;
}
.sc-r{
    border: 1px solid #E4E7ED;
    padding: 9px 10px;
    height: 420px;
}
.sm-item+.sm-item{
    margin-top: 10px;  
}
.sm-item.act{
    border: 1px solid #0e6ac1;
}
.sm-item{
    height: 97.5px;
    box-sizing: border-box;
    padding: 5px;
}
.sm-pic{
    width: calc(100% - 560px);
    height: 420px;
    overflow: hidden;
}
.bigpic{
    width: 540px;
    height: 420px;
}.act.page-item a{
    color: #f35c15;
    border-bottom:2px solid #f35c15;
}
.page-item:hover a{
    color: #f35c15;
    border-bottom:2px solid #f35c15;
}
.page-item{
    margin: 0 20px;
}
.page-name{
    width: 240px;
    color: #0a3695;  
}
.secondMenu{
    font-weight: 600;
    font-family: '微软雅黑';
    margin-top: 65px;;
    height: 60px;
    line-height: 60px;
    background: #eee;
    font-size: 20px;
}
</style>